export interface Package {
    price: number;
    credits: number;
    popular?: boolean;
};

export const PACKAGES: Package[] = [
    {
        price: 699,
        credits: 60,
    },
    {
        price: 1399,
        credits: 300,
    },
    {
        price: 3099,
        credits: 850,
        popular: true,
    },
    {
        price: 4599,
        credits: 1750,
    },
];

export const BONUS: number = 0.3;
export const FREE_CREDITS: number = 3;
